import {
	BreadcrumbItem,
	Breadcrumbs,
	Container,
	Dialog,
	DialogContent,
	DialogTrigger,
	Heading,
	HorizontalRule,
	Page,
	Picture,
	Section,
	TroonAccess,
} from '@troon/ui';
import { Icon } from '@troon/icons';
import { createMemo, For, Suspense, useContext } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { createAsync } from '@solidjs/router';
import { Title } from '@solidjs/meta';
import { PictureSection } from '../../../components/layouts/picture-section';
import { AccessProducts } from '../../../components/access-products';
import { AccessProductsCtx } from '../../../providers/card';
import { gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import { getConfigValue } from '../../../modules/config';
import { FacilitiesByGrouping } from '../../../components/facility/by-grouping';
import { ImageOverlay } from '../../../components/image-overlay';
import { FrequentlyAskedQuestions } from '../../../components/faqs';
import AccessBenefits from '../_components/benefits';
import type { RouteDefinition } from '@solidjs/router';
import type { TroonCardSubscriptionProduct } from '../../../graphql';
import type { ParentProps } from 'solid-js';

export default function GiftAccess() {
	const products = useContext(AccessProductsCtx);
	const data = createAsync(() => getData({}), { deferStream: true });

	const lowestPricedProduct = createMemo(() => {
		return products()?.reduce(
			(lowest, product) => (!lowest || product.totalAmount.value < lowest.totalAmount.value ? product : lowest),
			undefined as undefined | TroonCardSubscriptionProduct,
		);
	});

	return (
		<>
			<Title>Gift Troon Access | Troon</Title>
			<Container class="mt-4">
				<Breadcrumbs>
					<BreadcrumbItem href="/access">Troon Access</BreadcrumbItem>
					<BreadcrumbItem href="/access/gift">Gift Troon Access</BreadcrumbItem>
				</Breadcrumbs>
			</Container>
			<Page>
				<Container>
					<PictureSection
						src="https://images.ctfassets.net/rdsy7xf5c8dt/5AaUaTNn71RYudslEmlYZ5/a79893a183c99e42c61d28b2d7618651/TroonAccess_Gift.png"
						crop="center"
						mode="contain"
					>
						<Heading as="h1">
							<span class="sr-only">Troon Access</span>
							<TroonAccess class="w-full min-w-48 max-w-64" />
						</Heading>
						<Heading as="p" size="h1">
							The perfect gift for the golfer in your life.
						</Heading>
						<p>
							A Troon Access membership is the ultimate way to play Troon, with year-round savings on tee times at over
							150 world-class courses!
						</p>

						<div class="flex flex-wrap items-center justify-start gap-4">
							<Dialog key="access-products-gift">
								<DialogTrigger appearance="primary" class="pointer-events-auto shrink grow-0 flex-nowrap text-nowrap">
									Gift Troon Access <Icon name="arrow-right-md" />
								</DialogTrigger>
								<DialogContent header="Gift Troon Access" headerLevel="h3" autoWidth>
									<AccessProducts isGift forUser={false} />
								</DialogContent>
							</Dialog>

							<span class="text-nowrap font-medium text-neutral-800">
								Starting at just <Suspense>{lowestPricedProduct()?.totalAmount.displayValue}</Suspense>
							</span>
						</div>
					</PictureSection>
				</Container>

				<Container>
					<HorizontalRule />
				</Container>

				<Container>
					<Section>
						<CenterContent>
							<Heading as="h2" size="h1">
								You gift. They save.
							</Heading>
							<p>Choose a Troon Access plan below to continue your gift purchase:</p>
						</CenterContent>
						<div class="grid w-full grid-cols-3 gap-16">
							<BenefitCallout>
								<Icon name="select-multiple-custom" class="size-16" />
								<Heading as="h3">Choose a Plan</Heading>
								<p>Select the Troon Access or Troon Access+ membership, perfectly tailored to any golfer’s needs.</p>
							</BenefitCallout>
							<BenefitCallout>
								<Icon name="gift-custom" class="size-16" />
								<Heading as="h3">Personalize the Gift</Heading>
								<p>
									Add a message, schedule when you want to send it, and complete your purchase in just a few clicks.
								</p>
							</BenefitCallout>
							<BenefitCallout>
								<Icon name="mail-open-custom" class="size-16" />
								<Heading as="h3">Sent via Email</Heading>
								<p>
									The gift recipient receives an email on the scheduled date with easy-to-follow instructions to redeem
									their gift.
								</p>
							</BenefitCallout>
						</div>
					</Section>
				</Container>

				<Container size="medium" class="z-30 overflow-visible text-neutral-950">
					<AccessProducts floating isGift forUser={false} />
				</Container>

				<Container>
					<HorizontalRule />
				</Container>

				<Container>
					<Section>
						<Heading as="h2" size="h1" class="text-center">
							The gift that keeps on giving.
						</Heading>

						<AccessBenefits />

						<Dialog key="access-products-gift">
							<DialogTrigger appearance="primary" class="mx-auto size-fit">
								Gift Troon Access <Icon name="arrow-right-md" />
							</DialogTrigger>
							<DialogContent header="Gift Troon Access" headerLevel="h3" autoWidth>
								<AccessProducts isGift forUser={false} />
							</DialogContent>
						</Dialog>
					</Section>
				</Container>

				<Section appearance="primary" class="relative overflow-hidden">
					<Picture
						alt=""
						src={`https://${getConfigValue('MAP_HOST')}/assets/images/map-base.png`}
						class="absolute inset-x-0 top-0 -z-0 w-full bg-top object-cover"
						loading="lazy"
						sizes={[
							[480, 198],
							[960, 396],
							[1280, 528],
						]}
					/>

					<Container class="relative z-0">
						<For each={data()?.featured?.facilities?.slice(0, 2)}>
							{(feat, index) => (
								<Picture
									// eslint-disable-next-line tailwindcss/no-arbitrary-value
									class={twJoin(
										'absolute -z-0 hidden aspect-[3/4] w-[300px] rounded xl:block',
										index() % 2 ? '-right-6 top-12 -rotate-6' : '-left-12 bottom-12 rotate-6',
									)}
									alt=""
									src={feat.metadata?.hero?.url}
									sizes={[[300, 400]]}
									loading="lazy"
								/>
							)}
						</For>
						<CenterContent>
							<Heading as="h2" id="explore-courses">
								150+ World-class courses.
							</Heading>
							<p>
								Troon Access members can use their benefits across the entire network of participating Troon courses.
								Search courses by state below:
							</p>
						</CenterContent>

						<FacilitiesByGrouping facilities={data()?.facilities.facilities} inverse />
					</Container>
					<ImageOverlay class="absolute -bottom-1" />
				</Section>

				<Container>
					<HorizontalRule />
				</Container>

				<Section>
					<Container size="small">
						<Heading as="h2" size="h2" class="text-center">
							Frequently asked questions
						</Heading>
						<div class="overflow-hidden rounded border border-neutral">
							<FrequentlyAskedQuestions id="troon-access-gifting-faqs" />
						</div>
					</Container>
				</Section>
			</Page>
		</>
	);
}

function CenterContent(props: ParentProps) {
	return <div class="relative z-0 mx-auto flex max-w-xl flex-col gap-4 text-center">{props.children}</div>;
}

function BenefitCallout(props: ParentProps) {
	return <div class="col-span-3 flex flex-col items-center gap-6 text-center lg:col-span-1">{props.children}</div>;
}

const accessQuery = gql(`query accessGiftPage {
	facilities: facilitiesV3(supportsTroonAccess: true, sortBy: name) {
		facilities { ...FacilityByState }
	}
	featured: facilitiesV3(supportsTroonAccess: true, limit: 2) {
		facilities {
			metadata { hero { url } }
		}
	}
}`);

const getData = cachedQuery(accessQuery);

export const route = { info: { hasHero: false } } satisfies RouteDefinition;
