import { useCurrentMatches } from '@solidjs/router';
import { Icon } from '@troon/icons';
import { Link, Container } from '@troon/ui';
import { createMemo, Show, For } from 'solid-js';
import { NoHydration } from 'solid-js/web';

export function Footer() {
	const matches = useCurrentMatches();
	const isExtraMinimal = createMemo(() => {
		return matches().some((match) => match.route.info?.nav === 'extra-minimal');
	});
	return (
		<Show when={!isExtraMinimal()}>
			<NoHydration>
				<div class="border-t border-neutral pt-12">
					<Container class="flex flex-row flex-wrap gap-8">
						<div class="flex shrink grow basis-full flex-col gap-y-4 md:basis-5/12">
							<Link href="/">
								<Icon name="logo" class="w-28 shrink-0 grow-0 basis-28 text-neutral-900 hover:text-neutral-800" />
								<span class="sr-only">Troon home</span>
							</Link>
							<ul class="flex flex-col text-sm">
								<li class="mb-4">15044 N. Scottsdale Road, Suite 300, Scottsdale, AZ 85254</li>
								<li>
									<abbr title="Phone" class="font-semibold no-underline">
										P:
									</abbr>{' '}
									480.606.1000
								</li>
								<li>
									<abbr title="Fax" class="font-semibold no-underline">
										F:
									</abbr>{' '}
									480.606.1010
								</li>
							</ul>
							<Link href="/app" target="_blank">
								<img
									src="/assets/images/app-store.svg"
									alt="Troon - Available on the App Store"
									class="w-48"
									width="190"
									height="57"
									loading="lazy"
								/>
							</Link>
						</div>

						<nav title="Footer" class="shrink grow basis-full md:basis-6/12">
							<ul class="flex w-full flex-col flex-wrap justify-between gap-8 md:flex-row">
								<For each={navItems}>
									{(section) => (
										<li class="flex flex-col gap-2">
											<SectionLink {...section} />
											<ul class="flex flex-col gap-2">
												<For each={section.items}>
													{(item) => (
														<li>
															<NavLink {...item} />
														</li>
													)}
												</For>
											</ul>
										</li>
									)}
								</For>
							</ul>
						</nav>

						<div class="mt-12 flex w-full shrink-0 flex-wrap items-center gap-4 border-t border-neutral py-8 text-sm">
							<p>© {new Date().getFullYear()} Troon. All rights reserved.</p>
							<ul class="flex grow basis-full flex-wrap gap-4 md:basis-auto">
								<li>
									<Link class="text-nowrap underline hover:text-brand-600" href="/privacy-policy">
										Privacy policy
									</Link>
								</li>
								<li>
									<Link class="text-nowrap underline hover:text-brand-600" href="/terms-of-use">
										Terms of use
									</Link>
								</li>
							</ul>
							<ul class="mt-8 flex gap-3 md:mt-0">
								<For each={social}>
									{(item) => (
										<li>
											<Link href={item.href}>
												<img src={`/assets/images/${item.icon}`} alt="" loading="lazy" class="size-6" />
												<span class="sr-only">{item.title}</span>
											</Link>
										</li>
									)}
								</For>
							</ul>
						</div>
					</Container>
				</div>
			</NoHydration>
		</Show>
	);
}

function SectionLink(props: NavItem) {
	return (
		<Link href={props.href} class="font-semibold hover:underline">
			{props.title}
		</Link>
	);
}

function NavLink(props: NavItem) {
	return (
		<Link href={props.href} class="text-sm hover:underline">
			{props.title}
		</Link>
	);
}

type NavItem = {
	title: string;
	href: string;
};

type NavSection = {
	title: string;
	href: string;
	items: Array<NavItem>;
};

const navItems: Array<NavSection> = [
	{
		title: 'Play Troon',
		href: '/tee-times',
		items: [
			{ title: 'Find a Tee Time', href: '/tee-times' },
			{ title: 'Course Directory', href: '/courses' },
			{ title: 'Troon Rewards', href: '/rewards' },
			{ title: 'Troon Privé', href: '/member-programs' },
			{ title: 'Play Troon Programs', href: '/play-troon-programs' },
			{ title: 'Troon Access', href: '/access' },
			{ title: 'Troon Executive Card', href: '/card/troon-executive-card' },
		],
	},

	{
		title: 'Management Services',
		href: '/management-services',
		items: [
			{ title: 'Private Clubs', href: '/management-services/private-club' },
			{ title: 'Daily Fee & Resort', href: '/management-services/daily-fee-and-resort' },
			{ title: 'Municipal Properties', href: '/management-services/municipal' },
			{ title: 'Community Associations', href: '/management-services/community-associations' },
			{ title: 'Advisory Services', href: '/management-services/advisory-services' },
			{ title: 'Troon Partners Network', href: '/management-services/troon-partners-network' },
		],
	},
	{
		title: 'About us',
		href: '/about',
		items: [
			{ title: 'Press Releases', href: '/press-releases' },
			{ title: 'Career Opportunities', href: '/about/career-opportunities' },
			{ title: 'Diversity, Equity and Inclusion', href: '/about/dei' },
			{ title: 'Team Troon', href: '/about/team-troon' },
			{ title: 'Contact Us', href: '/about/contact' },
		],
	},
];

type Social = {
	title: string;
	href: string;
	icon: string;
};
const social: Array<Social> = [
	{ title: 'Troon on facebook', href: 'https://www.facebook.com/troon', icon: 'social-fb.svg' },
	{ title: 'Troon on Instagram', href: 'https://www.instagram.com/troon', icon: 'social-instagram.svg' },
	{ title: 'Troon on X (Twitter)', href: 'https://twitter.com/troon', icon: 'social-x-twitter.svg' },
	{ title: 'Troon on Linkedin', href: 'https://www.linkedin.com/company/troon/', icon: 'social-linkedin.svg' },
	{ title: 'Troon on Youtube', href: 'https://www.youtube.com/troon', icon: 'social-youtube.svg' },
];
